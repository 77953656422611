import { react2angular } from "react2angular";
import { classNameMapper } from "../../utils/classNameMapper";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useEffect, useMemo, useRef, useState } from "react";
import { withReduxProvider } from "../../services/withReduxProvider";
import { Button } from "../../design/Button";
import DownloadImage from "./DownloadImage";
import { ITruMap } from "../../types/ITruMap";
import { EpiMap } from "./Map";
import { TruTerritoryNavbar } from "./TruTerritoryNavbar/TruTerritoryNavbar";
import { IMap, ICreateMap, IMapAnnotationsResponse } from "../../types/IMap";
import { IWorkspace } from "../../types/IWorkspace";
import { getService } from "react-in-angularjs";
import {
  useAddAnnotationMutation,
  useCreateMapMutation,
  useDeleteAnnotationsMutation,
  useDeleteMapMutation,
  useEditAnnotationsMutation,
  useGetCollectionsQuery,
  useGetMapByUUIDQuery,
  useGetWorkspacesWithMapsQuery,
  useLazyGetAnnotationsQuery,
  useLazyGetCollectionQuery,
  useSendRenderingPreflightMutation,
  useUpdateMapMutation,
} from "../../slices/apiSlice";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import {
  setDrawingEnabled,
  setMap,
  setMapDirty,
  setTilesLoading,
  addCollection as addCollectionInStore,
  setAnnotationsRefreshedAt,
  setMarkerID,
  resetTruTerritoryState,
  setWorkspaceID,
  setAvailableCollections,
  setAggregates,
  clearForcedReload,
  invokeForcedReload,
  clearSelectedFeatures,
} from "../../slices/pagesSlice";
import { TruTerritoryBookmarks } from "./TruTerritoryBookmarks";
import { ToggleTriangle } from "./ToggleTriangle";
import { TruTerritorySide } from "./Side/TruTerritorySide";
import { ICollection } from "../../types/ICollection";
import { IRootScopeService } from "angular";
import { IStateParamsService, IStateService } from "angular-ui-router";
import { PreflightMap, getEmptyMap, getPreflightMap } from "../../data/map";
import { getTopLevelMaps } from "../../data/workspace";
import { TruTerritoryLayers } from "./TruTerritoryLayers";
import { store } from "../../store";
import { TruTerritoryTheme } from "./TruTerritoryTheme";
import moment from "moment";
import { updateGlobalState } from "../../slices/appSlice";
import { MapControls } from "./MapControls/MapControls";
import { TopNavbarSecondary } from "../../components/TopNavbarSecondary";
import { PermalinkModal } from "./PermalinkModal";
import { PublicControls } from "./PublicControls";
import { geocode } from "../../services/google-geocoder";
import { skipToken } from "@reduxjs/toolkit/query";
import equal from "fast-deep-equal";
import { TruTerritoryReports } from "./TruTerritoryReports";
import { TruTerritoryProgressBackdrop } from "./TruTerritoryProgressBackdrop";
import { ConfirmationModal, ConfirmationModalHandle } from "../../components/Mapping/ConfirmationModal";

interface ITruTerritoryProps {
  isPublicPage?: boolean;
}

export const TruTerritory: React.FC<ITruTerritoryProps & JSX.IntrinsicAttributes> = ({
  isPublicPage,
}: ITruTerritoryProps) => {
  const Storage = getService("Storage");
  const $stateParams = getService("$stateParams") as IStateParamsService;
  const $state = getService("$state") as IStateService;
  const MappingModals = getService("MappingModals");
  const hasMapAccess = getService("hasMapAccess");
  const INSTANCE_ID = getService("INSTANCE_ID");
  const $rootScope = getService("$rootScope") as IRootScopeService;

  const [getCollection] = useLazyGetCollectionQuery();
  const [initiateCreateMap] = useCreateMapMutation();
  const [getAnnotations] = useLazyGetAnnotationsQuery();
  const [addAnnotation] = useAddAnnotationMutation();
  const [editAnnotations] = useEditAnnotationsMutation();
  const [deleteAnnotations] = useDeleteAnnotationsMutation();
  const [makeDeleteMapRequest] = useDeleteMapMutation();

  const dispatch = useAppDispatch();

  const { entityID } = useAppSelector((state) => state.app);
  const {
    workspaceID: storedWorkspaceID,
    map,
    isMapDirty,
    topPanelSections,
    isSidePanelOpen,
    markerID,
    drawingEnabled,
    selectedFeatures,
    availableCollections,
    isSnippetToolOpen,
    forceReload,
    shapeOverlay,
  } = useAppSelector((state) => state.pages.truterritory);

  const [truMap, setTruMap] = useState<ITruMap>();
  const [isPermalinkModalOpen, setIsPermalinkModalOpen] = useState(false);
  const [preflightCurrent, setPreflightCurrent] = useState<PreflightMap | undefined>(undefined);
  const selectionConfModal = useRef<ConfirmationModalHandle>(null);

  const { data: workspaces, isFetching: workspacesFetching } = useGetWorkspacesWithMapsQuery();
  const { data: allCollections } = useGetCollectionsQuery(
    !isPublicPage ? { with: ["themes", "themes.rules"] } : skipToken
  );
  const { data: publicMap, error: publicMapError } = useGetMapByUUIDQuery(
    isPublicPage ? { UUID: $stateParams.UUID, mapOptions: makePublicMapOptions() } : skipToken
  );

  // Redirect to 404 page if we should be showing a public map but can't find it.
  useEffect(() => {
    if (isPublicPage && publicMapError) {
      console.log("Error loading map", publicMapError);
      $state.go("not-found");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicMap, publicMapError]);

  // Store the list of collections for easy access, either all collection or those on the public map.
  useEffect(() => {
    dispatch(setAvailableCollections(publicMap?.collections || allCollections || []));
  }, [allCollections, dispatch, publicMap]);

  // Handle global page state and navigation on component mount.
  useEffect(() => {
    dispatch(
      updateGlobalState({
        title: "TruTerritory",
        h1: "TruTerritory",
        activePage: "mapping",
        activeSection: "mapping",
      })
    );

    // If there are unsaved changes or an active selection, don't let the user leave the page without confirmation
    // NOTE: All modern browsers ignore these custom messages and will display their own instead.
    const unbind = $rootScope.$on("windowUnload", function (_e, response) {
      if (store.getState().pages.truterritory.isMapDirty && hasMapAccess("update")) {
        if (response.event.toState && response.event.toState.name.indexOf("mapping")) {
          response.message = "There are unsaved changes to this map. Are you sure you want to leave?";
        } else if (!response.event.toState) {
          response.message = "There are unsaved changes to this map";
        }
      } else if (store.getState().pages.truterritory.selectedFeatures) {
        response.message = "You have selected shapes on the map. Are you sure you want to leave?";
      }
    });

    return () => {
      unbind();
      dispatch(resetTruTerritoryState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Choose a workspace if none is set, preferring the one last used.
  const workspaceID: number | undefined = useMemo(() => {
    const prevID = storedWorkspaceID || Storage.getItem("workspaceID", entityID);

    if (workspacesFetching) return prevID;

    // If this workspace isn't available, but others are, pick one.
    if (workspaces?.length && (!prevID || !workspaces.find((w) => w.ID === prevID))) {
      return workspaces?.find((w) => w.default)?.ID || workspaces?.[0].ID;
    }

    return prevID;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedWorkspaceID, Storage.getItem("workspaceID", entityID), workspaces, workspacesFetching]);

  const workspace = workspaces?.find((w) => w.ID == workspaceID);

  // When workspaceID changes, persist it to local storage.
  useEffect(() => {
    if (workspacesFetching) return;

    if (workspaceID && Storage.getItem("workspaceID", entityID) != workspaceID) {
      Storage.setItem("workspaceID", workspaceID, entityID);
    }

    if (workspaceID && workspaceID != storedWorkspaceID) {
      dispatch(setWorkspaceID(workspaceID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceID, workspacesFetching]);

  // Clear the map when a new entity is selected.
  useEffect(() => {
    if (map && map?.entityID !== entityID) {
      loadEmptyMap(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityID]);

  // Load an initial map or collection.
  useEffect(() => {
    // Don't overwrite a loaded map.
    if (!truMap || map) return;

    // If a public map, load it.
    if (publicMap) {
      loadPublicMap(publicMap, truMap);
    }

    // Or if a collectionID was passed in via the URL, load it
    else if ($state.params.collectionID) {
      const ID = $state.params.collectionID;

      // Remove collectionID from the URL without reloading the page
      window.history.replaceState({}, document.title, location.pathname);

      // Load the collection
      getCollection({ ID, with: "themes,themes.rules" }).unwrap().then(addCollection);
    }

    // Or if we've recorded the previously viewed map, load that
    else if (Storage.getItem("mapID", entityID) && workspace) {
      const map = workspace?.maps
        .flatMap((m) => [m, ...(m.children || [])])
        .find((m) => m.ID === Storage.getItem("mapID", entityID));
      if (map) {
        addMap(map);
      } else {
        loadEmptyMap(true);
      }
    }

    // Or just start with an empty map.
    else if (workspace && entityID) {
      loadEmptyMap(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicMap, workspace, truMap, entityID]);

  // Render the map
  useEffect(() => {
    if (!map || !truMap || !availableCollections.length) return;

    renderMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, truMap, availableCollections, forceReload]);

  /**
   * Adds an arbitrary GeoJSON feature as an overlap on top of the map.
   */
  useEffect(() => {
    if (truMap) {
      truMap.setShapeOverlay(shapeOverlay || null);
    }
  }, [truMap, shapeOverlay]);

  const requirePassword = (map: IMap, first = true) => {
    MappingModals.openPassword(first).then(function (password: string) {
      dispatch(setMap({ ...map, password })); //?
    });
  };

  const loadPublicMap = (map: IMap, truMap: ITruMap) => {
    return addMap({ ...map, isPublic: true }, true, truMap);
  };

  function makePublicMapOptions(): { o: string[] } {
    const mapOptions: { o: string[] } = { o: [] };
    if ($state.params.o) {
      const o = $state.params.o;
      mapOptions.o = o instanceof Array ? o : [o];
    }

    return mapOptions;
  }

  /**
   * Loads an empty map
   * @param {bool} forget If true, forget the previous map
   * @return {void}
   */
  function loadEmptyMap(forget: boolean = true, saveMarker = false, workspaceIDOverride?: number): void {
    const finalWorkspaceID = workspaceIDOverride || workspaceID;
    if (!entityID || !finalWorkspaceID) return;

    const map = getEmptyMap(finalWorkspaceID, entityID);
    dispatch(setMapDirty(false));
    dispatch(setMap(map));
    truMap?.clear(saveMarker && markerID ? [markerID] : []);
    dispatch(clearSelectedFeatures());

    if (drawingEnabled) toggleDrawing();

    if (forget) Storage.removeItem("mapID", entityID);
  }

  const toggleDrawing = function () {
    const theMap = !map?.parentID ? map : workspace?.maps.find((m) => m.ID === map.parentID);
    if (!theMap || !truMap) return;

    if (!drawingEnabled) {
      truMap.enableDrawing(onCreate, onEdit, onDelete, onRefresh);
      getAnnotations({ id: theMap.ID }).unwrap().then(setDrawn);
    } else {
      truMap.disableDrawing();
    }

    // Wrap the callbacks so that they always draw the new current layer.
    function onCreate(feature: GeoJSON.Feature) {
      addAnnotation({
        id: theMap!.ID,
        feature,
      })
        .unwrap()
        .then(setDrawn);
    }
    function onEdit(features: GeoJSON.Feature[]) {
      editAnnotations({
        id: theMap!.ID,
        features,
      })
        .unwrap()
        .then(setDrawn);
    }
    function onDelete(ids: number[]) {
      deleteAnnotations({
        id: theMap!.ID,
        ids,
        after: moment(store.getState().pages.truterritory.annotationsRefreshedAt ?? new Date()),
      })
        .unwrap()
        .then(setDrawn);
    }
    function onRefresh() {
      getAnnotations({
        id: theMap!.ID,
        after: moment(store.getState().pages.truterritory.annotationsRefreshedAt ?? new Date()),
      })
        .unwrap()
        .then(setDrawn);
    }
    function setDrawn(response: IMapAnnotationsResponse) {
      truMap!.setDrawnItems(response.updated);
      truMap!.removeDrawnItems(response.deleted);
      dispatch(setAnnotationsRefreshedAt(response.timestamp));
    }

    dispatch(setDrawingEnabled(!drawingEnabled));
  };

  /**
   * Add the given collection to the map
   */
  const addCollection = function (collection: ICollection) {
    dispatch(addCollectionInStore(collection));
    dispatch(setMapDirty(true));
  };

  /**
   * If a map has been dropped on the canvas
   * @param  {object} map the added map
   * @return {Promise}
   */
  const addMap = function (newMap: IMap, checkDirtyState = true, truMap?: ITruMap) {
    // Make sure an unsaved map isn't overwritten by a new one
    const { isMapDirty, map: currentMap } = store.getState().pages.truterritory;
    if (checkDirtyState && isMapDirty && currentMap) {
      return (
        // If the user wants to save changes, do it.
        MappingModals.openSaveMapChanges(
          "Do you want to save before loading a new map?",
          saveMap.bind(null, currentMap),
          "Save",
          "Don't Save"
        )
          // If the user said no to saving changes, ignore changes.
          .catch(function () {
            dispatch(setMapDirty(false));
          })
          // Whatever they said, load the new map.
          .finally(function () {
            return addMap(newMap);
          })
      );
    }

    // Also check whether a selection is active (letting checkDirtyState boolean do double duty).
    if (checkDirtyState && store.getState().pages.truterritory.selectedFeatures) {
      selectionConfModal.current?.open("Your current selection will be lost if you load a new map.").then(() => {
        dispatch(clearSelectedFeatures());
        addMap(newMap);
      });
      return;
    }

    // If the map is in a different workspace, switch to that workspace
    if (newMap.workspaceID != workspaceID) {
      dispatch(setWorkspaceID(newMap.workspaceID));
    }

    Storage.setItem("mapID", newMap.ID, entityID);

    // Clear the map and set the canvas to clean
    truMap?.clear(markerID ? [markerID] : []);
    dispatch(setMapDirty(false));
    if (drawingEnabled) toggleDrawing();
    dispatch(setDrawingEnabled(false));
    dispatch(clearSelectedFeatures());
    dispatch(setMarkerID(undefined));
    dispatch(setAnnotationsRefreshedAt(undefined));
    dispatch(setMap(newMap));
  };

  const switchWorkspace = async function (ID: number) {
    if (isMapDirty && map) {
      try {
        await getService("MappingModals").openSaveMapChanges(
          "Would you like to save changes before switching workspaces?",
          saveMap.bind(null, map),
          "Save",
          "Don't Save"
        );
      } catch {
        // A rejection means "no, I don't want to save."
        dispatch(setMapDirty(false));
      }
    }

    dispatch(setWorkspaceID(ID));
    createNewMap(true, ID);
  };

  const createNewMap = function (saveMarker = false, workspaceIDOverride?: number) {
    // Create a new empty map
    loadEmptyMap(true, saveMarker, workspaceIDOverride);
  };

  const saveMap = function (map: Partial<IMap>): Promise<void> {
    return map.ID && (hasMapAccess("update") || (hasMapAccess("collaborate") && map.allowCollaboration))
      ? updateMap(map)
      : MappingModals.openSaveMap({ ...map, ID: undefined }, createMap, getTopLevelMaps(workspace!), false, workspace);
  };

  const [updateMapRequest] = useUpdateMapMutation();

  const updateMap = function (partial: Partial<IMap>): Promise<void> {
    if (!map?.ID) return Promise.resolve();

    return updateMapRequest({ ...partial, ID: map.ID })
      .unwrap()
      .then(function () {
        if (!map) return;

        dispatch(setMap({ ...map, ...partial }));
        dispatch(setMapDirty(false));
      });
  };

  const createMap = function (m: ICreateMap): Promise<void> {
    return initiateCreateMap(m)
      .unwrap()
      .then(function (newMap) {
        if (!workspace) return;

        dispatch(setMap(newMap));
        dispatch(setMapDirty(false));

        Storage.setItem("mapID", newMap.ID, entityID);
      });
  };

  const addMapById = (mapID: number) => {
    const foundMap = workspace?.maps.find((m) => m.ID === mapID);
    if (foundMap) {
      addMap(foundMap);
    }
  };

  const [sendRenderingPreflight] = useSendRenderingPreflightMutation();

  function renderMap() {
    if (!map || !truMap || !availableCollections.length) return;

    // Optionally require password on public map.
    if (map.passwordRequired && !map.password && map.isPublic) {
      requirePassword(map);
      return;
    }

    // Re-render the map only if the preflight is different.
    // This helps eliminate a variety of bugs because its easy to trigger
    // multiple renders accidentally which have no effect on the map itself,
    // e.g., creating a new theme triggers a render when the theme is created
    // and again when the theme is set active on the map, but only the latter
    // changes the preflight config.
    const preflight = getPreflightMap(map, availableCollections);
    if ((!forceReload && equal(preflight, preflightCurrent)) || !preflight) {
      return;
    }

    dispatch(clearForcedReload());

    // Also abort early if the preflight has no layers in it and we already have nothing displayed.
    // (Happens on initial page load).
    if (preflight.collections.length == 0 && !preflightCurrent) {
      return;
    }

    setPreflightCurrent(preflight);

    return sendRenderingPreflight(preflight)
      .unwrap()
      .then(function (data) {
        const tileLayer = truMap.addOrUpdateTruTileLayer(INSTANCE_ID);
        tileLayer.on("loading", function () {
          dispatch(setTilesLoading(true));
        });
        tileLayer.on("load", function () {
          dispatch(setTilesLoading(false));
        });

        // Set drawn items on public map.
        if (isPublicPage && map.annotations && map.annotations.features) {
          truMap.setDrawnItems(map.annotations.features as unknown as GeoJSON.FeatureCollection);
        }

        dispatch(setAggregates(data.aggregates));
      })
      .catch(function (fallback) {
        // Generate our functional error code / message
        const errorCode =
          ((fallback.data && fallback.data.status && fallback.data.status.code) || fallback.status) + "";
        const errorMessage =
          (fallback.data && fallback.data.status && fallback.data.status.message) || fallback.statusText;

        // Only popup a message if we have something meaningful to say
        if (errorCode !== "-1" && errorCode !== "403" && (errorCode || errorMessage)) {
          $rootScope.$broadcast("httpError", {
            error: {
              status: {
                exception: "generic",
                code: errorCode,
                message: "Error during map preflight: " + (errorMessage || "unknown error"),
              },
            },
          });
        }

        // Handle password rejected on public map.
        else if (errorCode === "403" && map.isPublic) {
          requirePassword(map, false);
        }

        return [];
      });
  }

  const reload = () => dispatch(invokeForcedReload());

  const deleteMap = async (m: IMap) => {
    await makeDeleteMapRequest(m);
    if (m.ID === map?.ID) loadEmptyMap(true, true);
  };

  const onMapSearch = async (text: string) => {
    const result = await geocode(text);

    if (!result || !result.location) return;

    if (markerID) truMap?.removeLayer(markerID);
    const newMarkerID = truMap?.addMarker([result.location.lat, result.location.lng], {
      input: text,
      formatted: result.address || "",
    });
    truMap?.setView([result.location.lat, result.location.lng], 14, { animate: true });
    if (newMarkerID) dispatch(setMarkerID(newMarkerID));
  };

  const selectionActions = {
    // clear undoes all selections and restore feature states.
    clear: () => {
      dispatch(clearSelectedFeatures());
    },

    // goTo zooms/pans as needed to fit the selection in the map view.
    goTo: () => {
      // Make a flat list of features.
      const features = [];
      for (const layerName in selectedFeatures) {
        for (const featureId in selectedFeatures[layerName]) {
          features.push(selectedFeatures[layerName][featureId]);
        }
      }

      truMap?.goTo(features);
    },

    // filterBy converts the selection into collection-level filters and applies them to the map.
    filterBy: () => {
      if (!selectedFeatures || !Object.keys(selectedFeatures).length || !map) {
        return;
      }

      // For each layer in the selection, filter down to matching feature IDs.
      for (const layerName in selectedFeatures) {
        const collection = availableCollections.find((c) => c.tableName == layerName);
        if (!collection) continue;

        const filter = {
          operator: "IN",
          column: collection.idColName,
          value: Object.keys(selectedFeatures[layerName]),
        };

        // To make this work predictably, we have to first remove any "IN" filter(s) on idColName.
        const baseFilters = (map.filters[collection.ID] || []).filter(
          (f) => !(f.operator == "IN" && f.column == collection.idColName)
        );

        // We add to existing filters for this collection (implicit AND), if present.
        dispatch(setMap({ ...map, filters: { ...map.filters, [collection.ID]: [...baseFilters, filter] } }));
      }

      // For layers not in the selection, don't do anything??
    },

    combine: () => {
      console.log("combining...");
    },
  };

  if (isPublicPage) {
    return (
      <div id="truterritory">
        <PublicControls truMap={truMap} map={map} onSearch={onMapSearch} />
        <div className="map-holder">
          <div className="map-holder">
            <EpiMap
              onInit={(truMap) => setTruMap(truMap)}
              actions={selectionActions}
              reload={reload}
              zoom={$stateParams.zoom}
              center={[$stateParams.lat, $stateParams.lng]}
            />
          </div>
          {truMap && <MapControls leafletMap={truMap.getLeafletMap()} />}
        </div>
        <ToggleTriangle />
        <div className="right-wrap right-side-menu right" style={isSidePanelOpen ? { width: "400px" } : {}}>
          <div id="side-menu">
            {isSidePanelOpen && map && <TruTerritorySide key={map.ID} isPublicPage={isPublicPage} />}
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div id="side-nav-right" className={"col-xs-2"}>
        <PermalinkModal isOpen={isPermalinkModalOpen} onClose={() => setIsPermalinkModalOpen(false)} truMap={truMap} />
        <Button
          id="truterritory-draw"
          className={classNameMapper({ enabled: drawingEnabled }, "btn")}
          disabled={!map?.ID}
          onClick={() => toggleDrawing()}
        />
        <div
          id="truterritory-permalink"
          {...{ disabled: !map?.ID || !workspace?.sharingEnabled }}
          onClick={() => map?.ID && workspace?.sharingEnabled && setIsPermalinkModalOpen(true)}
        />
        {truMap && (
          <>
            <div id="truterritory-download-image" className={isSnippetToolOpen ? "bounding-box-is-visible" : ""}>
              <DownloadImage truMap={truMap} disabled={!map} />
            </div>
            {map && <TruTerritoryBookmarks loadMap={addMap} deleteMap={deleteMap} currentMap={map} />}
          </>
        )}
      </div>
      {workspace ? (
        <TruTerritoryNavbar
          onSearch={onMapSearch}
          isMapDirty={isMapDirty}
          saveMap={saveMap}
          createMap={createMap}
          workspace={workspace as IWorkspace}
          switchWorkspace={switchWorkspace}
          createNewMap={loadEmptyMap}
          $scope={getService("$rootScope")}
          truMap={truMap}
        />
      ) : (
        <TopNavbarSecondary title={""} />
      )}
      <div id="content" className="row has-navbar">
        {(topPanelSections.layers || topPanelSections.theme || topPanelSections.mapReports) && (
          <div id="top-nav-slider" className="mapping">
            {topPanelSections.layers && (
              <TruTerritoryLayers deleteMap={deleteMap} switchWorkspace={switchWorkspace} addMap={addMapById} />
            )}
            {topPanelSections.theme && <TruTerritoryTheme />}
            {topPanelSections.mapReports && <TruTerritoryReports />}
          </div>
        )}
        <div id="truterritory">
          <div className="map-holder">
            <div className="map-holder" id="map-holder">
              <EpiMap onInit={(truMap) => setTruMap(truMap)} actions={selectionActions} reload={reload} />
            </div>
            {truMap && <MapControls leafletMap={truMap.getLeafletMap()} />}
          </div>
          <ToggleTriangle />
          <div className="right-wrap right-side-menu right" style={isSidePanelOpen ? { width: "400px" } : {}}>
            <div id="side-menu">
              {isSidePanelOpen && map && <TruTerritorySide key={map.ID} isPublicPage={isPublicPage} />}
            </div>
          </div>
        </div>
      </div>

      <TruTerritoryProgressBackdrop />

      <ConfirmationModal ref={selectionConfModal} />
    </>
  );
};

export const AngularTruterritory = react2angular(withReduxProvider(TruTerritory));
