import L from "leaflet";
import "@maplibre/maplibre-gl-leaflet";
import { useAppSelector } from "../../hooks/useAppSelector";
import { ITruMap } from "../../types/ITruMap";
import { useEffect, useMemo, useState } from "react";
import { findTheme } from "../../data/collection";
import {
  CircleLayerSpecification,
  LayerSpecification,
  LngLat,
  MapGeoJSONFeature,
  StyleSpecification,
  SymbolLayerSpecification,
} from "maplibre-gl";
import config from "../../../app/configuration";
import { IFilter, IMap } from "../../types/IMap";
import { getService } from "react-in-angularjs";
import { baseApi } from "../../slices/apiSlice";
import { Map as LibreGLMap } from "maplibre-gl";
import { store } from "../../store";
import { deselectFeatures, selectFeatures } from "../../slices/pagesSlice";
import MapboxPoint from "@mapbox/point-geometry";
import * as turf from "@turf/turf";
import { BBox } from "geojson";
import { ICollection } from "../../types/ICollection";
import { SelectedFeatures } from "../../pages/TruTerritory/MapSelectionActionsBar";
/* eslint-disable @typescript-eslint/no-require-imports */
const markerPng = require("../../../app/assets/assets/_/img/marker.png");

export type VectorPreflight = {
  instanceID: string;
  collections: { ID: number; filters: IFilter[] }[];
  minDate: string | null;
  maxDate: string | null;
  compInterval: string | null;
};

interface IProps {
  truMap: ITruMap;
}

export const VectorMap: React.FC<JSX.IntrinsicAttributes & IProps> = ({ truMap }: IProps) => {
  const instanceID = getService("INSTANCE_ID");
  const { map, availableCollections, selectedFeatures } = useAppSelector((state) => state.pages.truterritory);
  const [vectorUrl, setVectorUrl] = useState<string | null>(null);

  // Initialize Maplibre as a layer in Leaflet.
  const vectorLayer = useMemo(() => initializeMaplibre(truMap), [truMap]);

  // Get the list of collections we'll use in next steps.
  const collections = useMemo(
    () =>
      (map?.collectionIDs || [])
        .map((ID) => availableCollections.find((c) => c.ID == ID))
        .filter((c) => !!c)
        .filter((c) => map?.selectable[c.ID] && !!c.idColName),
    [availableCollections, map?.collectionIDs, map?.selectable]
  );

  const sourceLoaded = (() => {
    try {
      return !!vectorLayer.getMaplibreMap().getSource("tileSource")?.loaded();
      /* eslint-disable @typescript-eslint/no-unused-vars */
    } catch (_) {
      return false;
    }
  })();

  // Initialize the vector server on the backend.
  useEffect(() => {
    initializeServer(instanceID, map).then(setVectorUrl);
    // Deliberately not watching all of `map` so that we don't rerender unnecessarily.
  }, [instanceID, map?.dateRange, map?.compInterval, map?.filters, map?.collectionIDs, map?.selectable]);

  // Setup the vector style and all interactions (clicks, etc).
  // Runs on changes to URL, visibility.
  useEffect(
    () => initializeLayers(vectorUrl, vectorLayer, truMap, map?.visible, collections),
    [vectorUrl, vectorLayer, truMap, map?.visible, collections]
  );

  // Set feature state based on selection. Runs when collection list, selected features, or vector URL
  // changes, and only after the source is loaded and the vector layer is available.
  useEffect(
    () => displaySelection(sourceLoaded, vectorLayer, collections, selectedFeatures),
    [sourceLoaded, vectorLayer, collections, selectedFeatures, vectorUrl]
  );

  return <></>;
};

/**
 * Setup the maplibreGL Leaflet plugin and return an instance to the result vectors layer.
 */
function initializeMaplibre(truMap: ITruMap) {
  const vectors = L.maplibreGL({
    interactive: true,
    // Disable most zoom/pan interactions so that Leaflet can control it all
    boxZoom: false,
    scrollZoom: false,
    doubleClickZoom: false,
    keyboard: false,
    dragPan: false,
    dragRotate: false,
    touchPitch: false,
    touchZoomRotate: false,
  }).addTo(truMap.getLeafletMap());
  truMap.setVectorLayer(vectors);

  // Make the marker image available for use in styles.
  const maplibreMap = vectors.getMaplibreMap();
  maplibreMap.on("load", async () => {
    const img = await maplibreMap.loadImage(markerPng);
    maplibreMap.addImage("marker-image", img.data);
  });

  return vectors;
}

/**
 * Send the preflight request to initialize the source on the backend. When we're done, return the source URL
 * to inidicate it's ready for use.
 */
async function initializeServer(instanceID: string, map: IMap | undefined): Promise<string | null> {
  if (map?.isPublic) {
    return null;
  }

  // Prepare the preflight request.
  const preflight = {
    instanceID,
    collections:
      map?.collectionIDs.filter((id) => map.selectable[id]).map((id) => ({ ID: id, filters: map.filters[id] || [] })) ||
      [],
    minDate: map?.dateRange?.minDate ? map?.dateRange.minDate.format("YYYY-MM-DD HH:mm:ss") : null,
    maxDate: map?.dateRange?.maxDate ? map?.dateRange.maxDate.format("YYYY-MM-DD HH:mm:ss") : null,
    compInterval: map?.compInterval || null,
  };

  if (!preflight.collections.length) {
    return null;
  }

  // Send the preflight request
  await store.dispatch(baseApi.endpoints.sendVectorPreflight.initiate(preflight)).unwrap();

  // On response, set a cache-busted source URL
  const cacheBuster = `ts=${Date.now()}`;
  return `${config.TEGOLA_URL}/maps/${instanceID}/{z}/{x}/{y}.pbf?${cacheBuster}`;
}

/**
 * Add or update the layers with their styles and interactions (click, etc).
 * Returns cleanup function as required by useEffect().
 */
function initializeLayers(
  vectorUrl: string | null,
  vectorLayer: L.MaplibreGL,
  truMap: ITruMap,
  visiblility: IMap["visible"] | undefined,
  collections: ICollection[]
) {
  if (!vectorUrl || !visiblility) {
    return;
  }

  const layerNames: string[] = [];
  const layerIdColumns: { [key: string]: string } = {};
  const layerThemesCSS: { [key: string]: string } = {};
  const layerVisibility: { [key: string]: boolean } = {};
  const visibleLayerNames: string[] = [];
  for (const collection of collections) {
    layerNames.push(collection.tableName);
    layerIdColumns[collection.tableName] = collection.idColName || "";
    layerThemesCSS[collection.tableName] = findTheme(collection).css;
    layerVisibility[collection.tableName] = !!visiblility[collection.ID];
    if (visiblility[collection.ID]) {
      visibleLayerNames.push(collection.tableName);
    }
  }

  const maplibreMap = vectorLayer.getMaplibreMap();
  maplibreMap.setStyle(makeVectorStyle(vectorUrl, layerIdColumns, layerNames, layerThemesCSS, layerVisibility));

  // Add interactions (for visible layers only)
  const cleanupClick = addClickSelectHandler(maplibreMap, visibleLayerNames);
  const cleanupMarquee = addMarqueeSelectHandler(truMap.getLeafletMap(), maplibreMap, visibleLayerNames);

  return () => {
    // cleanup selection handlers
    cleanupClick();
    cleanupMarquee();
  };
}

/**
 * Make the complete style object which defines the vector source and all its layers.
 */
function makeVectorStyle(
  url: string,
  layerIds: { [key: string]: string },
  layerNames: string[],
  layerThemesCSS: { [key: string]: string },
  layerVisibility: { [key: string]: boolean }
): StyleSpecification {
  return {
    version: 8,
    sources: {
      tileSource: {
        type: "vector",
        tiles: [url],
        promoteId: layerIds,
      },
    },
    layers: layerNames.flatMap((name) => makeVectorLayerStyles(name, layerThemesCSS[name], layerVisibility[name])),
  };
}

/**
 * Make the style objects for a specific vector layer.
 */
function makeVectorLayerStyles(name: string, css: string, visible: boolean): LayerSpecification[] {
  const useSymbol = _getIsSymbolFromCss(css);
  const markerWidth = _getMarkerWidthFromCss(css);
  const markerTransform = _getMarkerTransformFromCss(css);
  // adjust x by 0.5px because mapnik and maplibre aren't lining up perfectly.
  markerTransform[0] += 0.5;

  // Three layers are required: a fill to receive clicks, a line to highlight, and a symbol/circle layer (in case there are points).
  return [
    {
      id: name,
      source: "tileSource",
      "source-layer": name,
      type: "fill",
      layout: {
        visibility: "visible",
      },
      paint: {
        "fill-color": [
          "case",
          ["boolean", ["feature-state", "selected"], false],
          "rgba(63,96,165,0.2)",
          "rgba(63,96,165,0)", // hidden when selected=false
        ],
      },
    },
    {
      id: `${name}-border`,
      source: "tileSource",
      "source-layer": name,
      type: "line",
      layout: {
        visibility: "visible",
      },
      paint: {
        "line-color": [
          "case",
          ["boolean", ["feature-state", "selected"], false],
          "#3f60a5",
          "rgba(63,96,165,0)", // hidden when selected=false
        ],
        "line-width": 2,
      },
    },
    useSymbol
      ? symbolMarkerLayer(name, markerTransform, markerWidth)
      : circleMarkerLayer(name, markerTransform, markerWidth),
  ];
}

/**
 * Use a symbol to display a point/marker. This depends on "marker-image" already being defined as
 * a usable image in Maplibre.
 */
function symbolMarkerLayer(
  name: string,
  markerTransform: [number, number],
  markerWidth: number
): SymbolLayerSpecification {
  return {
    id: `${name}-marker`,
    source: "tileSource",
    "source-layer": name,
    type: "symbol",
    layout: {
      visibility: "visible",
      "icon-image": "marker-image",
      "icon-size": markerWidth / 200, // icon-size is really icon-width
      "icon-anchor": "center",
      "icon-allow-overlap": true,
    },
    paint: {
      "icon-color": "white", // Our icon still must not be SDF, as we still can't change color.
      "icon-translate": markerTransform,
      "icon-opacity": [
        "case",
        ["all", ["==", ["geometry-type"], "Point"], ["boolean", ["feature-state", "selected"], false]],
        0.6,
        0,
      ],
    },
  };
}

/**
 * Use a circle to display a point/marker.
 */
function circleMarkerLayer(
  name: string,
  markerTransform: [number, number],
  markerWidth: number
): CircleLayerSpecification {
  return {
    id: `${name}-marker`,
    source: "tileSource",
    "source-layer": name,
    type: "circle",
    layout: {
      visibility: "visible",
    },
    paint: {
      "circle-color": "white",
      "circle-translate": markerTransform,
      "circle-radius": markerWidth / 2,
      "circle-opacity": ["case", ["boolean", ["feature-state", "selected"], false], 0.5, 0],
    },
  };
}

/**
 * Add a handler to select or deselect a single feature on shift/ctrl+click or alt+click.
 */
function addClickSelectHandler(maplibreMap: LibreGLMap, layerNames: string[]) {
  maplibreMap.on("click", clickHandler);

  return () => {
    maplibreMap.off("click", clickHandler);
  };

  /* eslint-disable @typescript-eslint/no-explicit-any */
  function clickHandler(e: any) {
    const features = maplibreMap.queryRenderedFeatures(e.point);

    let selected: MapGeoJSONFeature | undefined;
    // For each source layer, check its polygon style layer first, then its symbol style layer.
    for (const layerName of layerNames.flatMap((name) => [name, `${name}-marker`])) {
      selected = features.find((f) => f.layer.id == layerName);
      if (selected != undefined) {
        break;
      }
    }

    if (!selected) {
      return;
    }

    const select = shouldSelect(e),
      deselect = shouldDeselect(e);
    if (select || deselect) {
      if (select) {
        store.dispatch(selectFeatures([selected]));
      } else {
        store.dispatch(deselectFeatures([selected]));
      }
    }
  }
}

/**
 * Add handlers to select or deselect multiple features in a box drawn by shift/ctrl+mousedown+drag or alt+mousedown+drag.
 */
function addMarqueeSelectHandler(leafletMap: L.Map, maplibreMap: LibreGLMap, layerNames: string[]) {
  let startPoint: MapboxPoint | null,
    currentPoint: MapboxPoint | null,
    startLatLng: LngLat | null,
    currentLatLng: LngLat | null,
    box: HTMLElement | null,
    adding: boolean;

  maplibreMap.on("mousedown", mouseDown);

  return () => {
    maplibreMap.off("mousedown", mouseDown);
  };

  /* eslint-disable @typescript-eslint/no-explicit-any */
  function mouseDown(e: any) {
    startPoint = currentPoint = startLatLng = currentLatLng = null;

    const select = shouldSelect(e),
      deselect = shouldDeselect(e);

    // Don't do anything if the user isn't holding down shift/ctrl or alt.
    if (!select && !deselect) {
      return;
    }

    leafletMap.dragging.disable();
    maplibreMap.on("mousemove", mouseMove);
    maplibreMap.on("mouseup", mouseUp);

    startPoint = e.point;
    startLatLng = e.lngLat;
    adding = select; // if not adding, then removing (alt).
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  function mouseMove(e: any) {
    if (!startPoint) {
      return;
    }
    currentPoint = e.point as MapboxPoint;
    currentLatLng = e.lngLat;

    if (!box) {
      box = document.createElement("div");
      box.classList.add("marquee-select");
      maplibreMap.getCanvasContainer().appendChild(box);
    }

    const minX = Math.min(startPoint.x, currentPoint.x),
      maxX = Math.max(startPoint.x, currentPoint.x),
      minY = Math.min(startPoint.y, currentPoint.y),
      maxY = Math.max(startPoint.y, currentPoint.y);

    const pos = `translate(${minX}px, ${minY}px)`;
    box.style.transform = pos;
    box.style.width = maxX - minX + "px";
    box.style.height = maxY - minY + "px";
  }

  function mouseUp() {
    leafletMap.dragging.enable();
    maplibreMap.off("mousemove", mouseMove);
    maplibreMap.off("mouseup", mouseUp);

    if (box) {
      box.parentNode?.removeChild(box);
      box = null;
    }

    if (!startPoint || !currentPoint || !startLatLng || !currentLatLng) {
      return;
    }

    const bounds: [MapboxPoint, MapboxPoint] = [startPoint, currentPoint];
    const latlngs = [startLatLng, currentLatLng];
    startPoint = currentPoint = startLatLng = currentLatLng = null;

    // For marquee select, we select ONLY from the topmost selectable layer.
    const features = maplibreMap.queryRenderedFeatures(bounds, { layers: [layerNames[0]] });
    if (!features) {
      return;
    }

    const boundsPolygon = turf.bboxPolygon(latlngs.flatMap((b) => [b.lng, b.lat]) as BBox);
    const finalFeatures = features.filter((f) => {
      // Get the bounding box of the shape. If it's entirely contained inside the selection box, it's definitely in.
      const geo = f.toJSON();
      // toJSON() leaves behind _geometry which can be huge and makes future copying of this object extremely expensive. Clear it.
      // @ts-expect-error (because the Maplibre types show this field as GeoJSON.Geometry, even though it's really GeoJSON.Geometry | undefined)
      delete f._geometry;

      // For speed, if the feature's bounding box is entirely contained in the selection box, we don't need to calculate its area, etc.
      if (turf.booleanContains(boundsPolygon, turf.bboxPolygon(turf.bbox(geo)))) {
        return true;
      }

      // Count this feature as selected only if the selection box covers more than 30% of its area.
      const intersection = turf.intersect(turf.featureCollection([geo, boundsPolygon]));
      if (!intersection) {
        return false;
      }

      return turf.area(intersection) / turf.area(geo) > 0.3;
    });

    if (adding) {
      store.dispatch(selectFeatures(finalFeatures));
    } else {
      store.dispatch(deselectFeatures(finalFeatures));
    }
  }
}

/**
 * Detect a shift or ctrl/cmd keypress from the given event, depending on OS.
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
function shouldSelect(event: any) {
  const isMac = !!(
    "userAgentData" in navigator && navigator.userAgentData instanceof Object && "platform" in navigator.userAgentData
      ? (navigator.userAgentData.platform as string)
      : navigator.platform
  ).match(/^mac/i);
  return (
    !!event.originalEvent.shiftKey ||
    (isMac && !!event.originalEvent.metaKey) ||
    (!isMac && !!event.originalEvent.ctrlKey)
  );
}

/**
 * Detect an alt/option keypress from the given event.
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
function shouldDeselect(event: any) {
  return !!event.originalEvent.altKey;
}

/**
 * Set feature state on all features in all layers to match the current selection.
 */
function displaySelection(
  sourceLoaded: boolean,
  vectorLayer: L.MaplibreGL,
  collections: ICollection[],
  selectedFeatures: SelectedFeatures | undefined
) {
  if (!vectorLayer || !sourceLoaded) {
    return;
  }

  const maplibreMap = vectorLayer.getMaplibreMap();
  const layerNames = collections.map((c) => c.tableName);
  for (const name of layerNames) {
    maplibreMap.removeFeatureState({ source: "tileSource", sourceLayer: name });
    maplibreMap.removeFeatureState({ source: "tileSource", sourceLayer: name + "-border" });
    maplibreMap.removeFeatureState({ source: "tileSource", sourceLayer: name + "-marker" });
  }

  if (!selectedFeatures) {
    return;
  }

  for (const layerName in selectedFeatures) {
    for (const id in selectedFeatures[layerName]) {
      maplibreMap.setFeatureState(
        {
          source: selectedFeatures[layerName][id].source,
          sourceLayer: selectedFeatures[layerName][id].sourceLayer,
          id: selectedFeatures[layerName][id].id,
        },
        {
          selected: true,
        }
      );
    }
  }
}

/**!!!!!!
 * WARNING: The following CSS functions are totally naive and are meant as a hack until we get off of CartoCSS.
 * They will give unexpected results if CSS is commented out, or if properties are used more than once (e.g., 
 * because of conditional styles).
 * DON'T DO MORE OF THIS.
 *!!!!!!!/

/**
 * Check for the presence of a marker file in the CartoCSS (and thus the need for us to use a symbol layer)
 * @return bool
 */
function _getIsSymbolFromCss(css: string): boolean {
  if (!css) return false;
  const re = /marker-file:/;
  return !!css.match(re);
}

/**
 * Get the marker-width value from the given CartoCSS.
 */
function _getMarkerWidthFromCss(css: string): number {
  if (!css) return 0;
  const re = /marker-width:\s*(\d+)/;
  const match = css.match(re);
  return (match && parseInt(match[1])) || 0;
}

/**
 * Get the x,y transform values from marker-transform rom the given CartoCSS.
 */
function _getMarkerTransformFromCss(css: string): [number, number] {
  if (!css) return [0, 0];
  const re = /marker-transform:\s*translate\(([\-]{0,1}\d+),([\-]{0,1}\d+)/;
  const match = css.match(re);
  return (match && [parseFloat(match[1]), parseFloat(match[2])]) || [0, 0];
}
