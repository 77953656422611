import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CollectionTypes } from "../types/CollectionTypes";
import { ICollection, ITheme, IThemeWithID } from "../types/ICollection";
import { IMap } from "../types/IMap";
import { BackendActionInvocation } from "../pages/TruTerritory/MapBackendActions";
import { getService } from "react-in-angularjs";
import { ReportInvocation } from "../pages/TruTerritory/ReportDownloader";
import { SelectedFeature, SelectedFeatures } from "../pages/TruTerritory/MapSelectionActionsBar";
import * as geojson from "geojson";

interface PagesState {
  data: IDataState;
  truterritory: ITruTerritoryState;
}

interface IDataState {
  showArchived: boolean;
  selectedTypesFilter: string[];
  searchString?: string;
  consoleCollection?: ICollection;
  currentlyEditingCollection?: ICollection["ID"] | "create";
}

export interface ITruTerritoryState {
  map?: IMap;
  aggregates?: Record<string, Record<string, string>>;
  availableCollections: ICollection[];
  workspaceID?: number;

  currentEditingCollection?: {
    properties: ICollection["properties"];
    aggregates: ICollection["aggregates"];
  };
  currentEditingTheme?: ITheme;

  topPanelSections: {
    layers: boolean;
    theme: boolean;
    mapReports: boolean;
  };
  isSidePanelOpen: boolean;
  isMapDirty: boolean;
  isSnippetToolOpen: boolean;
  forceReload: boolean;

  backendActionInvocation: BackendActionInvocation | undefined;
  reportInvocation: ReportInvocation | undefined;
  tilesLoading: boolean;
  markerID?: number;
  drawingEnabled: boolean;
  annotationsRefreshedAt?: string;
  selectedFeatures?: SelectedFeatures;
  shapeOverlay?: geojson.Feature;

  progress?: {
    text: string;
    value: number;
    determinate: boolean;
  };
}

export const initialState: PagesState = {
  data: { showArchived: false, selectedTypesFilter: CollectionTypes.map(({ type }) => type) },
  truterritory: {
    availableCollections: [],
    drawingEnabled: false,
    topPanelSections: {
      layers: false,
      theme: false,
      mapReports: false,
    },
    isSidePanelOpen: false,
    isMapDirty: false,
    isSnippetToolOpen: false,
    forceReload: false,
    backendActionInvocation: undefined,
    reportInvocation: undefined,
    tilesLoading: false,
  },
};

export const pagesSlice = createSlice({
  name: "pages",
  initialState,
  reducers: {
    // data collections
    toggleShowArchived: (state) => {
      state.data.showArchived = !state.data.showArchived;
    },
    toggleType: (state, { payload }: PayloadAction<IDataState["selectedTypesFilter"][0]>) => {
      if (state.data.selectedTypesFilter.includes(payload)) {
        state.data.selectedTypesFilter = state.data.selectedTypesFilter.filter((t) => t !== payload);
      } else {
        state.data.selectedTypesFilter.push(payload);
      }
    },
    setDataSearch: (state, { payload }: PayloadAction<IDataState["searchString"]>) => {
      state.data.searchString = payload;
    },
    resetDataState: (state) => {
      state.data = initialState.data;
    },

    setConsoleCollection: (state, { payload }: PayloadAction<IDataState["consoleCollection"]>) => {
      state.data.consoleCollection = payload;
    },
    setCurrentlyEditingCollection: (state, { payload }: PayloadAction<IDataState["currentlyEditingCollection"]>) => {
      state.data.currentlyEditingCollection = payload;
    },

    //#region truterritory
    resetTruTerritoryState: (state) => {
      state.truterritory = initialState.truterritory;
    },
    setMap: (state, { payload: map }: PayloadAction<ITruTerritoryState["map"]>) => {
      state.truterritory.map = map;

      if (!map) return;
    },
    updateMap: (state, { payload: map }: PayloadAction<Partial<ITruTerritoryState["map"]>>) => {
      if (!state.truterritory.map) return;

      state.truterritory.map = { ...state.truterritory.map, ...map };
    },
    setAvailableCollections: (state, { payload }: PayloadAction<ICollection[]>) => {
      state.truterritory.availableCollections = payload;
    },
    setMarkerID: (state, { payload }: PayloadAction<ITruTerritoryState["markerID"]>) => {
      state.truterritory.markerID = payload;
    },
    toggleTopPanelSection: (state, { payload }: PayloadAction<keyof ITruTerritoryState["topPanelSections"]>) => {
      state.truterritory.topPanelSections[payload] = !state.truterritory.topPanelSections[payload];

      // close all the other panels
      Object.keys(state.truterritory.topPanelSections).forEach((section) => {
        if (section !== payload) state.truterritory.topPanelSections[section as keyof ITruTerritoryState["topPanelSections"]] = false;
      });
    },
    closeTopPanelSections: (state) => {
      state.truterritory.topPanelSections = initialState.truterritory.topPanelSections;
    },
    setSidePanelOpen(state, { payload }: PayloadAction<boolean>) {
      state.truterritory.isSidePanelOpen = payload;
    },
    setMapDirty(state, { payload }: PayloadAction<boolean>) {
      const hasMapAccess = getService("hasMapAccess");

      // Only dirty the map if the user has permission to save it
      if (!payload || (state.truterritory.map?.allowCollaboration && hasMapAccess("collaborate")) || hasMapAccess("update")) {
        state.truterritory.isMapDirty = payload;
      }
    },
    setIsSnippetToolOpen(state, { payload }: PayloadAction<boolean>) {
      state.truterritory.isSnippetToolOpen = payload;
    },
    setCurrentlyEditingTheme: (state, { payload }: PayloadAction<ITruTerritoryState["currentEditingTheme"]>) => {
      state.truterritory.currentEditingTheme = payload;
    },
    setCurrentlyEditingThemeAndCollection: (
      state,
      { payload: { theme, collection } }: PayloadAction<{ theme: ITheme | IThemeWithID; collection: Required<ITruTerritoryState>["currentEditingCollection"] }>
    ) => {
      state.truterritory.currentEditingTheme = theme;
      state.truterritory.currentEditingCollection = {
        properties: collection.properties,
        aggregates: collection.aggregates,
      };
    },
    updateCurrentlyEditingTheme: (state, { payload }: PayloadAction<Partial<ITheme>>) => {
      if (!state.truterritory.currentEditingTheme) return;
      state.truterritory.currentEditingTheme = { ...state.truterritory.currentEditingTheme, ...payload };
    },
    setWorkspaceID: (state, { payload: workspaceID }: PayloadAction<ITruTerritoryState["workspaceID"]>) => {
      state.truterritory.workspaceID = workspaceID;
    },
    setBackendActionInvocation(state, { payload: invocation }: PayloadAction<BackendActionInvocation | undefined>) {
      state.truterritory.backendActionInvocation = invocation;
    },
    setReportInvocation(state, { payload: invocation }: PayloadAction<ReportInvocation | undefined>) {
      state.truterritory.reportInvocation = invocation;
    },
    setDrawingEnabled: (state, { payload }: PayloadAction<boolean>) => {
      state.truterritory.drawingEnabled = payload;
    },
    setTilesLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.truterritory.tilesLoading = payload;
    },
    setMapTheme: ({ truterritory }, { payload: { collectionID, theme } }: PayloadAction<{ collectionID: number; theme: IThemeWithID | ITheme }>) => {
      if (!truterritory.map) return;

      // if this is the default theme, it won't have an id
      if (theme.ID) {
        truterritory.map.themes[collectionID] = theme.ID;
      } else {
        delete truterritory.map.themes[collectionID];
      }

      // Always use the themes's label
      truterritory.map.labels[collectionID] = theme.label;
    },
    removeMapTheme: (state, { payload: themeID }: PayloadAction<number>) => {
      if (!state.truterritory.map) return;

      for (const collectionID in state.truterritory.map.themes) {
        if (state.truterritory.map.themes[collectionID] === themeID) {
          delete state.truterritory.map.themes[collectionID];
        }
      }
    },
    setMapLabel: (state, { payload: { label, collectionID } }: PayloadAction<{ collectionID: number; label: string | null }>) => {
      if (!state.truterritory.map) return;

      state.truterritory.map.labels[collectionID] = label;
    },
    addCollection: (state, { payload: collection }: PayloadAction<ICollection>) => {
      const { truterritory } = state;
      if (!truterritory.map) return;

      truterritory.map.collectionIDs.push(collection.ID);
      truterritory.map.visible[collection.ID] = true;
    },
    removeCollection: (state, { payload: ID }: PayloadAction<number>) => {
      const { truterritory } = state;
      if (!truterritory.map) return;

      truterritory.map.collectionIDs = truterritory.map.collectionIDs.filter((c) => c !== ID);
      delete truterritory.map.visible[ID];
      delete truterritory.map.filters[ID];
      delete truterritory.map.themes[ID];
      delete truterritory.map.labels[ID];
    },
    setAnnotationsRefreshedAt: (state, { payload }: PayloadAction<ITruTerritoryState["annotationsRefreshedAt"]>) => {
      state.truterritory.annotationsRefreshedAt = payload;
    },
    selectFeatures: (state, { payload: features }: PayloadAction<SelectedFeature[]>) => {
      if (!state.truterritory.selectedFeatures) {
        state.truterritory.selectedFeatures = {};
      }

      for (const feature of features) {
        if (!feature.sourceLayer || !feature.id) continue;

        if (!(feature.sourceLayer in state.truterritory.selectedFeatures)) {
          state.truterritory.selectedFeatures[feature.sourceLayer] = {};
        }

        state.truterritory.selectedFeatures[feature.sourceLayer][feature.id] = feature;
      }
    },
    deselectFeatures: (state, { payload: features }: PayloadAction<SelectedFeature[]>) => {
      if (!state.truterritory.selectedFeatures) {
        return;
      }

      for (const feature of features) {
        if (!feature.sourceLayer || !feature.id || !(feature.sourceLayer in state.truterritory.selectedFeatures)) continue;

        delete state.truterritory.selectedFeatures[feature.sourceLayer][feature.id];
        if (Object.keys(state.truterritory.selectedFeatures[feature.sourceLayer]).length === 0) {
          delete state.truterritory.selectedFeatures[feature.sourceLayer];
        }
      }

      if (Object.keys(state.truterritory.selectedFeatures).length === 0) {
        state.truterritory.selectedFeatures = undefined;
      }
    },
    clearSelectedFeatures: (state) => {
      state.truterritory.selectedFeatures = undefined;
    },
    setAggregates: (state, { payload }: PayloadAction<ITruTerritoryState["aggregates"]>) => {
      state.truterritory.aggregates = payload;
    },
    setProgress: (state, { payload }: PayloadAction<ITruTerritoryState["progress"]>) => {
      state.truterritory.progress = payload;
    },
    invokeForcedReload: (state) => {
      state.truterritory.forceReload = true;
    },
    clearForcedReload: (state) => {
      state.truterritory.forceReload = false;
    },
    showShapeOverlay: (state, { payload }: PayloadAction<ITruTerritoryState["shapeOverlay"]>) => {
      state.truterritory.shapeOverlay = payload;
    },
    hideShapeOverlay: (state) => {
      state.truterritory.shapeOverlay = undefined;
    }
    //#endregion
  },
});

export const {
  toggleShowArchived,
  toggleType,
  setDataSearch,
  resetDataState,
  setConsoleCollection,
  setCurrentlyEditingCollection,
  resetTruTerritoryState,
  setMap,
  setAvailableCollections,
  toggleTopPanelSection,
  closeTopPanelSections,
  setSidePanelOpen,
  setCurrentlyEditingTheme,
  setCurrentlyEditingThemeAndCollection,
  updateCurrentlyEditingTheme,
  setWorkspaceID,
  setMapDirty,
  setIsSnippetToolOpen,
  setBackendActionInvocation,
  setReportInvocation,
  setDrawingEnabled,
  setTilesLoading,
  updateMap,
  setMapTheme,
  removeMapTheme,
  setMapLabel,
  addCollection,
  removeCollection,
  setAnnotationsRefreshedAt,
  setMarkerID,
  selectFeatures,
  deselectFeatures,
  clearSelectedFeatures,
  setAggregates,
  setProgress,
  invokeForcedReload,
  clearForcedReload,
  showShapeOverlay,
  hideShapeOverlay,
} = pagesSlice.actions;

export default pagesSlice.reducer;
